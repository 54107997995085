import GameTitleImagePng from "@/assets/images/home-screen/game-title.png";
import ShibaKingImage from "@/assets/images/home-screen/shiba-king.png";
import SettingShibaImage from "@/assets/images/setting-screen/setting-shiba.png";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import { useConfigStore } from "@/services/stores/useConfigStore";
import React from "react";

// Language Option Component
const LanguageOption = ({
  language,
  selected,
  onClick,
}: {
  language: { name: string; code: string };
  selected: boolean;
  onClick: () => void;
}) => (
  <div
    className={`flex justify-between items-center bg-gradient-to-r from-purple-600 to-blue-400 py-3 px-4 rounded-lg shadow-md cursor-pointer mb-2 ${
      selected ? "border-2 border-yellow-400" : ""
    }`}
    onClick={onClick}
  >
    <div className="flex items-center">
      <img
        src={ShibaKingImage}
        alt={language.name}
        className="w-8 h-8 rounded-full mr-2"
      />
      <span className="text-white font-semibold text-lg">{language.name}</span>
    </div>
  </div>
);

// Switch Component for Game Effects
const Switch = ({
  label,
  isChecked,
  onToggle,
}: {
  label: string;
  isChecked: boolean;
  onToggle: () => void;
}) => (
  <div className="flex justify-between items-center mb-4">
    <span className="text-white font-semibold">{label}</span>
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onToggle}
        className="sr-only peer"
      />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:bg-blue-600 transition-colors duration-300"></div>
      <div
        className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-300 transform ${
          isChecked ? "translate-x-5" : ""
        }`}
      ></div>
      <span className="ml-3 text-white">
        <b>{isChecked ? "On" : "Off"}</b>
      </span>
    </label>
  </div>
);

const SettingsScreen: React.FC = () => {
  // Access config and translations from the zustand store
  const {
    language,
    vibrate,
    sound,
    setLanguage,
    toggleVibrate,
    toggleSound,
    translations,
  } = useConfigStore();

  // Language array with {name, code}
  const languages = [
    { name: translations.settings_screen.language_options.en, code: "en" }, // English
    { name: translations.settings_screen.language_options.vi, code: "vi" }, // Vietnamese
  ];

  return (
    <PhoneScreenLayout>
      <div className="flex flex-col items-center space-y-4 w-full mx-auto">
        {/* Header */}
        <section className="text-center flex flex-col items-center justify-center w-full">
          <div className="flex justify-center items-center">
            <img src={GameTitleImagePng} alt="Home Title" className="w-full" />
          </div>
        </section>

        {/* Image */}
        <section>
          <img
            src={SettingShibaImage}
            alt={"setting-shiba"}
            className="w-full h-auto mr-2"
          />
        </section>

        {/* Language Selection */}
        <section className="w-full mt-4">
          <h2 className="text-xl text-white font-semibold mb-2">
            {translations.settings_screen.languages}
          </h2>
          <div className="max-h-72 overflow-y-auto">
            {languages.map((languageOption, index) => (
              <LanguageOption
                key={index}
                language={languageOption}
                selected={languageOption.code === language}
                onClick={() => setLanguage(languageOption.code)}
              />
            ))}
          </div>
        </section>

        {/* Game Effects */}
        <section className="w-full mt-4">
          <h2 className="text-xl text-white font-semibold mb-2">
            {translations.settings_screen.game_effects}
          </h2>
          <Switch
            label={translations.settings_screen.vibrate}
            isChecked={vibrate}
            onToggle={toggleVibrate}
          />
          <Switch
            label={translations.settings_screen.sound}
            isChecked={sound}
            onToggle={toggleSound}
          />
        </section>
      </div>

      {/* Navigation */}
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default SettingsScreen;
