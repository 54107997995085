import { createMutation, createQuery } from "react-query-kit";
import { checkFacebookVerification, verifyFacebookToken } from "./request";
import { IFacebookVerificationResponse, IFacebookVerifyRequest } from "./types";

export const useVerifyFacebookToken = createMutation<
  IFacebookVerificationResponse,
  IFacebookVerifyRequest
>({
  mutationFn: verifyFacebookToken,
});

export const useCheckFacebookVerification = createQuery({
  queryKey: ["useCheckFacebookVerification"],
  fetcher: (): Promise<IFacebookVerificationResponse> =>
    checkFacebookVerification(),
});
