import api from "@/services/apis/api";
import { IFacebookVerificationResponse, IFacebookVerifyRequest } from "./types";

export const verifyFacebookToken = async (
  data: IFacebookVerifyRequest
): Promise<IFacebookVerificationResponse> => {
  const response: IFacebookVerificationResponse = await api.post(
    "/api/user/social/facebook/verify/",
    data
  );
  return response;
};

export const checkFacebookVerification =
  async (): Promise<IFacebookVerificationResponse> => {
    const response: IFacebookVerificationResponse = await api.get(
      "/api/user/social/facebook/is-verified/"
    );
    return response;
  };
