import CrownImagePng from "@/assets/images/crown.png";
import GoldCoinImagePng from "@/assets/images/rank-image/gold-coin.png";
import GoldPointImagePng from "@/assets/images/rank-image/gold-point.png";
import ProfileImagePng from "@/assets/images/rank-image/profile.png";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import { IRankInfo, useRank } from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// A simple ListRank component that displays the list of rank items.
const ListRank: React.FC<{ ranks: IRankInfo[] }> = ({ ranks }) => {
  const { translations } = useConfigStore();
  return (
    <div>
      {ranks.length === 0 ? (
        <p className="text-sm text-white h-20 flex justify-center items-center rounded-[16px] bg-gradient-card">
          {translations.rank_screen.no_invites}
        </p>
      ) : (
        <ul className="text-sm">
          {ranks.map((rank, index) => (
            <li
              key={rank.user_id}
              className="bg-gradient-card flex py-1 justify-around items-center mt-1 rounded-[16px]"
            >
              <div className="flex justify-center items-center text-center text-base">
                {[0, 1, 2].includes(index) ? (
                  <div>
                    <img src={CrownImagePng} alt="Crown" className="w-8 mr-2" />
                  </div>
                ) : (
                  <div className="w-8 mr-2" />
                )}
                <div className="flex items-center">
                  <b className="mr-1">{index + 1}</b>
                  <div className="w-8 h-8 mr-2 relative">
                    <div
                      className="absolute bg-yellow-500 text-white text-xs rounded-full top-[50%] left-[50%] w-8 h-8"
                      style={{ transform: "translate(-50%, -50%)" }}
                    />
                    <div
                      className="absolute bg-violet-800 text-white text-xs rounded-full top-[50%] left-[50%] w-7 h-7"
                      style={{ transform: "translate(-50%, -50%)" }}
                    />
                    <img
                      src={ProfileImagePng}
                      alt="Profile"
                      className="absolute top-[50%] left-[50%] w-6 h-6"
                      style={{ transform: "translate(-50%, -50%)" }}
                    />
                  </div>
                </div>
              </div>
              <div className="text-base flex-1">
                {rank?.username || translations.rank_screen.unknown}
              </div>
              <div className="text-base flex-[2]">
                <div className="text-yellow-300 text-base flex justify-start items-center">
                  <img
                    src={GoldPointImagePng}
                    alt="Point Coin Logo"
                    className="size-5 mx-1"
                  />
                  <i>{Number(rank.experience_point).toFixed(0)}</i>
                </div>
                <div className="text-yellow-300 text-base flex justify-start items-center">
                  <img
                    src={GoldCoinImagePng}
                    alt="Coin Logo"
                    className="size-5 mx-1"
                  />
                  <i>{Number(rank.coin_balance).toFixed(2)}</i>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const RankScreen: React.FC = () => {
  const { getUserName } = useAuthStore();
  const { translations } = useConfigStore();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const per_page = 20;
  const [ranks, setRanks] = useState<IRankInfo[]>([]);

  const { data, isFetching } = useRank({
    variables: { page: pageNumber, per_page },
  });
  const hasNext: boolean = data?.has_next !== undefined ? data?.has_next : true;

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      if (pageNumber === 1) {
        setRanks(data.data);
      } else {
        setRanks((prev) => [...prev, ...data.data]);
      }
    }
  }, [data, pageNumber]);

  const fetchMoreData = () => {
    if (hasNext) {
      setPageNumber((prev) => prev + 1);
    }
  };

  return (
    <PhoneScreenLayout>
      <div className="max-w-md mx-auto">
        <TopUserProfile username={getUserName()} />
        <div className="text-center mb-4 flex justify-center items-center mt-4">
          <h1 className="text-4xl font-bold pr-4 text-[#FFDB17]">
            {translations.rank_screen.title}
          </h1>
          <img src={CrownImagePng} alt="Crown" className="w-auto h-10" />
        </div>
        <div className="bg-gradient-card w-full h-16 rounded-[16px] flex flex-col justify-center items-center">
          <div className="text-center text-base flex justify-center items-center">
            {translations.rank_screen.your_points}
            <b> {"("}</b>
            <img
              src={GoldPointImagePng}
              alt="Point Coin Logo"
              className="size-5 mx-1"
            />
            <b>
              {`) : ${Number(data?.user?.experience_point || 0).toFixed(0)}`}
            </b>
          </div>
          <p className="text-center text-sm">
            {translations.rank_screen.points_accumulate}
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-base font-bold">
            {translations.rank_screen.leaderboard}
          </h2>
          {pageNumber === 1 && isFetching ? (
            <p className="text-base text-center py-5 text-white">
              {translations.rank_screen.loading}
            </p>
          ) : (
            <InfiniteScroll
              dataLength={ranks.length}
              next={fetchMoreData}
              hasMore={hasNext}
              loader={
                <p className="text-center py-3 text-white">
                  {translations.rank_screen.loading_more}
                </p>
              }
              endMessage={
                <p className="text-center text-white mt-3">
                  {hasNext
                    ? "Scroll down to load more."
                    : "No more ranks available."}
                </p>
              }
            >
              <ListRank ranks={ranks} />
            </InfiniteScroll>
          )}
        </div>
      </div>
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default RankScreen;
