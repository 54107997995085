import AstronautPlanetPng from "@/assets/images/earn-screen/energy-collection-planet/astronaut-planet.png";
import AstronautPng from "@/assets/images/earn-screen/energy-collection-planet/astronaut.png";
import showToast from "@/commons/toast.common";
import { UrlMapping } from "@/commons/url-mapping.common";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import PopupModal from "@/components/PopupModal"; // Assuming you have a PopupModal component
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import config from "@/env";
import { useTonConnect } from "@/hooks/useTonConnect";
import { useTransaction } from "@/hooks/useTransaction"; // Import custom hook
import {
  IResponsePaymentTransaction,
  usePlanetRewards,
  useRequestPayment,
  useTransactionHistory,
} from "@/services/apis/king-of-shiba"; // Importing API hooks
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore";
import { formatTon } from "@/utils/number";
import { shortenAddress } from "@/utils/transaction_string";
import { beginCell } from "@ton/ton";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// TransactionModal Component
const TransactionModal: React.FC<{
  open: boolean;
  onClose: () => void;
  transactionData: IResponsePaymentTransaction | null;
  onConfirm: () => void;
  isPending: boolean;
  isSuccess: boolean;
  error: string | null;
  transactionId: string | null;
}> = ({
  open,
  onClose,
  transactionData,
  onConfirm,
  isPending,
  isSuccess,
  error,
  transactionId,
}) => {
  const { translations } = useConfigStore(); // Access translations

  return (
    <PopupModal open={open} onClose={onClose}>
      <div className="min-h-[30vh] flex flex-col justify-around h-full">
        <h2 className="text-2xl font-bold text-yellow-400">
          {translations.lucky_box_screen.amazing}
        </h2>
        <p className="text-white mt-2 text-center">
          {translations.lucky_box_screen.transaction_details}
        </p>

        {/* Transaction Info */}
        <div className="mt-4">
          <div className="flex pb-12">
            <img
              src={AstronautPng}
              alt="Astronaut to the moon"
              className="w-[50%] scale-125 animate-pulse "
            />
            <img
              src={transactionData?.planet_reward_lucky.image}
              alt={transactionData?.planet_reward_lucky.name}
              className="w-[10rem] h-auto ml-10"
            />
          </div>
          <p>
            <span className="font-bold">Name Planet:</span>{" "}
            {transactionData?.planet_reward_lucky.name}
          </p>
          <p>
            <span className="font-bold">Your spend:</span>{" "}
            {formatTon(Number(transactionData?.amount_payment), 10)} TON
          </p>
          <p>
            <span className="font-bold">To get:</span>{" "}
            <b className="text-yellow-400">
              {transactionData?.planet_reward_lucky.reward_multiplier} Energy
            </b>
            {" * "}
            <b className="text-green-400">Random number</b>
          </p>
          <p>
            <span className="font-bold">Transaction Code:</span>{" "}
            {transactionData?.transaction_code}
          </p>
          <div className="flex w-full justify-center p-3">
            <span className="font-bold text-center text-lg text-yellow-200">
              Good luck!
            </span>
          </div>{" "}
          {/* Transaction Status */}
          {isPending && (
            <p className="text-yellow-500 mt-4">
              {translations.lucky_box_screen.transaction_pending}
            </p>
          )}
          {isSuccess && (
            <p className="text-green-500 mt-4">
              {translations.lucky_box_screen.transaction_success}
            </p>
          )}
          {error && (
            <p className="text-red-500 mt-4">
              {translations.lucky_box_screen.transaction_error}: {error}
            </p>
          )}
          {transactionId && (
            <p className="text-blue-400 mt-4">
              {"Transaction ID: "}
              <a
                href={`${config.EXPLORER_SCAN}/transaction/${transactionId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {shortenAddress(transactionId)}
              </a>
            </p>
          )}
        </div>

        <button
          className={`bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 text-white w-full px-4 py-2 rounded-md mt-4 ${
            isSuccess ? "opacity-70" : ""
          }`}
          onClick={onConfirm}
          disabled={isPending || isSuccess}
        >
          {translations.lucky_box_screen.confirm_button}
        </button>
      </div>
    </PopupModal>
  );
};

const EnergyCollectionScreen: React.FC = () => {
  const { getUserName } = useAuthStore();
  const { data: planets } = usePlanetRewards({});
  const { mutate: requestPayment } = useRequestPayment();
  const { data: transactions, refetch: refetchTransactionHistory } =
    useTransactionHistory({});

  const {
    isPending,
    isSuccess,
    error,
    transactionId,
    sendTransaction,
    resetTransactionState,
  } = useTransaction();
  const navigate = useNavigate();

  const { sendTransaction: tonSendTransaction, isConnected } = useTonConnect();

  const [activeTab, setActiveTab] = useState<"planets" | "history">("planets"); // Tab state
  const [popupOpen, setPopupOpen] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [transactionData, setTransactionData] =
    useState<IResponsePaymentTransaction | null>(null); // Store transaction data
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef<HTMLImageElement>(null);

  // Reset transaction state
  const resetState = () => {
    setTransactionData(null);
    setPaymentAmount(0);
    setPopupOpen(false);
    resetTransactionState();
  };

  // Check wallet connection and navigate if not connected
  const onClickPlanet = (planet: any) => {
    if (!isConnected) {
      showToast("You need to connect your wallet first", "info");
      navigate(UrlMapping.airdrop); // Redirect to wallet connection page
      return;
    }

    resetState();

    // Request payment for selected planet
    requestPayment(
      { planet_reward_lucky_id: planet.id },
      {
        onSuccess: (data) => {
          setPaymentAmount(Number(data.amount_payment) || 0);
          setTransactionData(data); // Set transaction data
          setPopupOpen(true); // Open modal with transaction info
        },
        onError: () => {
          showToast("Payment request failed!", "error");
        },
      }
    );
  };

  const handleClickTransactionTab = () => {
    setActiveTab("history");
    refetchTransactionHistory();
  };
  const handleClickPlanetsTab = () => {
    setActiveTab("planets");
  };
  // Handle contract transaction via TON with transaction code as payload
  const handleContractTransaction = async () => {
    const body = beginCell()
      .storeUint(0, 32)
      .storeStringTail(transactionData?.transaction_code || "") // Send the transaction code as payload
      .endCell();

    const transaction = {
      messages: [
        {
          address: config.ENERGY_COLLECTION_WALLET_ADDRESS,
          amount: paymentAmount.toString(),
          payload: body.toBoc().toString("base64"),
        },
      ],
      validUntil: Math.floor(Date.now() / 1000) + 3600, // Valid for 1 hour
    };

    try {
      await sendTransaction(transaction, tonSendTransaction);
    } catch (error) {
      showToast("Transaction failed!", "error");
    }
  };

  return (
    <PhoneScreenLayout>
      <TopUserProfile username={getUserName()} />
      <section className="w-full text-center pb-4">
        <h1 className="text-2xl font-semibold mb-1">Energy collection</h1>
        <h5 className="font-semibold">Select one planet to explorer!</h5>
      </section>
      <div className="">
        <section
          className="w-full relative"
          style={{ height: imageHeight ? `${imageHeight}px` : "auto" }}
        >
          <img
            src={AstronautPlanetPng}
            alt="Energy collection"
            className="absolute scale-110"
            ref={imageRef}
            onLoad={() => {
              if (imageRef.current) {
                setImageHeight(imageRef.current.height);
              }
            }}
          />
        </section>

        {/* Tab Navigation */}
        <div className="flex justify-center my-4 space-x-4">
          <button
            className={`px-6 py-2 rounded-full text-sm font-semibold transition-all duration-300 transform ${
              activeTab === "planets"
                ? "bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-lg scale-105"
                : "bg-gray-300 text-gray-700 hover:bg-gray-400"
            }`}
            onClick={handleClickPlanetsTab}
          >
            Planet List
          </button>
          <button
            className={`px-6 py-2 rounded-full text-sm font-semibold transition-all duration-300 transform ${
              activeTab === "history"
                ? "bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-lg scale-105"
                : "bg-gray-300 text-gray-700 hover:bg-gray-400"
            }`}
            onClick={handleClickTransactionTab}
          >
            Transaction History
          </button>
        </div>

        {/* Planet List Tab */}
        {activeTab === "planets" && (
          <section className="planet-list w-full text-center pt-3">
            <h2 className="text-xl font-semibold pb-3">Select a Planet</h2>
            <div className="grid grid-cols-2 gap-4">
              {planets?.map((planet) => (
                <div
                  key={planet.id}
                  className={`bg-gradient-card rounded-[16px] shadow-lg text-white cursor-pointer `}
                  onClick={() => onClickPlanet(planet)}
                >
                  <div className="flex justify-center items-center border-b-[1px] border-[#4A5696]">
                    <div className="w-16 border-r-[1px] border-[#4A5696] flex justify-center items-center p-0.5">
                      <div className="size-1/2 p flex-col justify-center items-center">
                        <div className="text-[#F0F900] text-xs">
                          ID.{planet.id}
                        </div>
                      </div>
                    </div>
                    <div className="flex-col justify-center items-center w-full">
                      <div className="text-center text-sm">{planet.name}</div>
                      <div className="text-center text-[#F0F900] text-xs">
                        Pay: {formatTon(Number(planet.price_in_ton), 10)} TON
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center m-2">
                    <img
                      src={planet.image}
                      alt="Rocket Engine"
                      className="w-full min-h-20 min-w-20 max-w-[70%] max-h-[70%]"
                    />
                  </div>
                  <div className="text-center text-yellow-400 mb-1 text-[0.8rem] border-t-[1px] border-[#4A5696]">
                    You will be rewarded with {planet.reward_multiplier}X
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}

        {/* Transaction History Tab */}
        {activeTab === "history" && (
          <section className="transaction-history w-full text-center pt-3">
            <h2 className="text-xl font-semibold pb-3">Transaction History</h2>
            <div
              className="p-1 rounded-md shadow-md"
              style={{
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              {transactions?.map((transaction) => (
                <div
                  key={transaction.id}
                  className="transaction-item bg-gradient-card p-3 rounded-lg mb-2 flex items-center justify-between"
                >
                  <div className="flex flex-col justify-start">
                    <p className="text-left">
                      Pay: {formatTon(Number(transaction.amount_payment), 10)}{" "}
                      TON
                    </p>
                    <p className="text-left">
                      Reward: {transaction?.reward_amount || "0.000000"}
                    </p>
                  </div>
                  <div>
                    {transaction.status !== "unpaid" ? (
                      <p className="text-left">
                        <a
                          href={`${config.EXPLORER_SCAN}/transaction/${transaction?.transaction_hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          View On Explorer
                        </a>
                      </p>
                    ) : (
                      <p className="text-gray-400">Transaction unpaid!</p>
                    )}
                    <p className="text-left">Status: {transaction.status}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
      </div>
      <NavBar />
      <TransactionModal
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        transactionData={transactionData}
        onConfirm={handleContractTransaction}
        isPending={isPending}
        isSuccess={isSuccess}
        error={error}
        transactionId={transactionId}
      />
    </PhoneScreenLayout>
  );
};

export default EnergyCollectionScreen;
