import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import { ListFriend } from "@/pages/friend/components/ListFriend";
import { IFriend, useListFriend } from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

const ListFriendScreen: React.FC = () => {
  const { getUserName } = useAuthStore();
  const { translations } = useConfigStore();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const per_page = 20;
  const [friends, setFriends] = useState<IFriend[]>([]);

  const { data, isFetching } = useListFriend({
    variables: { page: pageNumber, per_page },
  });
  const hasNext: boolean = data?.has_next !== undefined ? data?.has_next : true;

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      if (pageNumber === 1) {
        setFriends(data.data);
      } else {
        setFriends((prev) => [...prev, ...data.data]);
      }
    }
  }, [data, pageNumber]);

  const fetchMoreData = () => {
    if (hasNext) {
      setPageNumber((prev) => prev + 1);
    }
  };

  return (
    <PhoneScreenLayout>
      <div className="max-w-md mx-auto">
        <TopUserProfile username={getUserName()} />
        <div className="text-center mb-4">
          <h1 className="text-2xl font-bold mt-4">
            {translations.list_friend_screen.refer_friends}
          </h1>
          <p className="text-sm">
            {translations.list_friend_screen.bonus_message}
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold">
            {translations.list_friend_screen.invited_friends_list}
          </h2>
          {pageNumber === 1 && isFetching ? (
            <p className="text-base text-center py-5 text-white">
              {translations.list_friend_screen.loading}
            </p>
          ) : (
            <InfiniteScroll
              dataLength={friends.length}
              next={fetchMoreData}
              hasMore={hasNext}
              loader={
                <p className="text-center py-3 text-white">
                  {translations.list_friend_screen.loading_more}
                </p>
              }
              endMessage={
                <p className="text-center text-white text-base mt-3">
                  {hasNext
                    ? "Scroll down to load more."
                    : "No more friends available."}
                </p>
              }
            >
              <ListFriend friends={friends} />
            </InfiniteScroll>
          )}
        </div>
      </div>
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default ListFriendScreen;
