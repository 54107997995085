import ChestImagePng from "@/assets/images/earn-screen/lucky-box-screen/chest.png";
import DailyGameLuckyImagePng from "@/assets/images/earn-screen/lucky-box-screen/daily-game-lucky.png";
import LuckyBoxTitleImagePng from "@/assets/images/earn-screen/lucky-box-screen/lucky-box-title.png";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import PopupModal from "@/components/PopupModal";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import {
  IResponseOpenBox,
  useOpenBox,
  useOpenBoxInfo,
} from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import translations
import { getRandomRange } from "@/utils/random";
import React, { useState } from "react";

type LuckyBoxModalProps = {
  open: boolean;
  onClose: () => void;
  rewardAmount: number;
};

const LuckyBoxModal: React.FC<LuckyBoxModalProps> = ({
  open,
  onClose,
  rewardAmount,
}) => {
  const { translations } = useConfigStore(); // Access translations

  return (
    <PopupModal open={open} onClose={onClose}>
      <div className="mb-4 flex justify-center relative">
        <img
          src={DailyGameLuckyImagePng}
          alt="Chest with golden coins"
          className="mx-auto w-4/5"
        />
        <div className="w-full flex justify-center items-center absolute z-10 left-0">
          <div className="bg-gradient-to-b from-[#60A196] to-[#0A7163] flex justify-center items-center px-4 w-[13.2rem] h-[5rem] rounded-md shadow-lg">
            <i className="text-[#F0F900] text-[2rem]">+{rewardAmount}</i>
          </div>
        </div>
      </div>

      <h2 className="text-yellow-400 text-2xl font-bold">
        {translations.lucky_box_screen.amazing}
      </h2>
      <p className="text-white mt-2 text-center">
        {translations.lucky_box_screen.congratulations_message}
      </p>
    </PopupModal>
  );
};

const LuckyBoxScreen: React.FC = () => {
  const { getUserName } = useAuthStore();
  const { translations } = useConfigStore(); // Access translations
  const [popupOpen, setPopupOpen] = useState(false);
  const { data: dataOpenBoxInfo, refetch } = useOpenBoxInfo({});
  const [indexBox, setIndexBox] = useState(4);
  const { mutate, data: dataResponseOpenBox } = useOpenBox({
    onSuccess: (data: IResponseOpenBox) => {
      setPopupOpen(true);
      refetch();
    },
  });

  const onClickConfirm = () => {
    const timeout = 5000;

    new Promise<void>((resolve) => {
      const intervalId = setInterval(() => {
        const randomNumber = getRandomRange(0, 8);
        setIndexBox(randomNumber);
      }, timeout / 30);

      setTimeout(() => {
        clearInterval(intervalId);
        setTimeout(() => {
          resolve();
        }, 1000);
      }, timeout);
    }).then(() => mutate());
  };

  return (
    <PhoneScreenLayout>
      <TopUserProfile username={getUserName()} />
      <div className="relative w-full min-h-[80vh] bg-cover flex flex-col justify-around items-center">
        <section className="w-full text-center py-10 scale-110">
          <img
            src={LuckyBoxTitleImagePng}
            alt={translations.lucky_box_screen.lucky_box_image_alt}
            className=""
          />
        </section>
        <section className="w-full text-center py-2">
          <h4 className="font-semibold">
            {translations.lucky_box_screen.unlock_treasure_prompt}
          </h4>
        </section>

        <section className="grid grid-cols-3 gap-2 p-3 bg-white rounded-[27px] border-[3px] border-[#B57BFE]">
          {Array(9)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                className={`relative flex flex-col items-center p-2 bg-gradient-game-lucky-box rounded-[16px] transition-all duration-500 ${
                  indexBox === index ? "border-[5px] border-rose-400" : ""
                }`}
              >
                <img
                  src={ChestImagePng}
                  alt={translations.lucky_box_screen.chest_image_alt}
                  className="w-full h-full"
                />
              </div>
            ))}
        </section>

        <button
          className={`w-full p-3 bg-gradient-button rounded-[16px] mt-5 text-lg ${
            !dataOpenBoxInfo?.able_open_box && "opacity-70 cursor-not-allowed"
          }`}
          disabled={!dataOpenBoxInfo?.able_open_box}
          onClick={onClickConfirm}
        >
          {dataOpenBoxInfo?.able_open_box
            ? translations.lucky_box_screen.confirm_button
            : translations.lucky_box_screen.confirmed_button}
        </button>
      </div>
      <NavBar />
      <LuckyBoxModal
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        rewardAmount={dataResponseOpenBox?.tokens_rewarded || 0}
      />
    </PhoneScreenLayout>
  );
};

export default LuckyBoxScreen;
