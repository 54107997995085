import { createMutation, createQuery } from "react-query-kit";
import { checkTwitterVerification, verifyTwitterToken } from "./request";
import { ITwitterVerificationResponse, ITwitterVerifyRequest } from "./types";

export const useVerifyTwitterToken = createMutation<
  ITwitterVerificationResponse,
  ITwitterVerifyRequest
>({
  mutationFn: verifyTwitterToken,
});

export const useCheckTwitterVerification = createQuery({
  queryKey: ["useCheckTwitterVerification"],
  fetcher: (): Promise<ITwitterVerificationResponse> =>
    checkTwitterVerification(),
});
