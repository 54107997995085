import { createMutation, createQuery } from "react-query-kit";
import { checkGoogleVerification, verifyGoogleToken } from "./request";
import { IGoogleVerificationResponse, IGoogleVerifyRequest } from "./types";

export const useVerifyGoogleToken = createMutation<
  IGoogleVerificationResponse,
  IGoogleVerifyRequest
>({
  mutationFn: verifyGoogleToken,
});

export const useCheckGoogleVerification = createQuery({
  queryKey: ["useCheckGoogleVerification"],
  fetcher: (): Promise<IGoogleVerificationResponse> =>
    checkGoogleVerification(),
});
