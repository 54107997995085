import { createMutation, createQuery } from "react-query-kit";
import { checkTikTokVerification, verifyTikTokToken } from "./request";
import { ITikTokVerificationResponse, ITikTokVerifyRequest } from "./types";

export const useVerifyTikTokToken = createMutation<
  ITikTokVerificationResponse,
  ITikTokVerifyRequest
>({
  mutationFn: verifyTikTokToken,
});

export const useCheckTikTokVerification = createQuery({
  queryKey: ["useCheckTikTokVerification"],
  fetcher: (): Promise<ITikTokVerificationResponse> =>
    checkTikTokVerification(),
});
