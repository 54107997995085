import api from "@/services/apis/api";
import { ITikTokVerificationResponse, ITikTokVerifyRequest } from "./types";

export const verifyTikTokToken = async (
  data: ITikTokVerifyRequest
): Promise<ITikTokVerificationResponse> => {
  const response: ITikTokVerificationResponse = await api.post(
    "/api/user/social/twitter/verify/",
    data
  );
  return response;
};

export const checkTikTokVerification =
  async (): Promise<ITikTokVerificationResponse> => {
    const response: ITikTokVerificationResponse = await api.get(
      "/api/user/social/twitter/is-verified/"
    );
    return response;
  };
