import GameCoinLogoPng from "@/assets/images/coin-icon.png";
import FacebookLogoPng from "@/assets/images/earn-screen/social-screen/facebook-logo.png";
import InstagramLogoPng from "@/assets/images/earn-screen/social-screen/instagram-logo.png";
import TelegramLogoPng from "@/assets/images/earn-screen/social-screen/telegram-logo.png";
import TikTokLogoPng from "@/assets/images/earn-screen/social-screen/tiktok-logo.png";
import TwitterLogoPng from "@/assets/images/earn-screen/social-screen/twitter-logo.png";
import YoutubeLogoPng from "@/assets/images/earn-screen/social-screen/youtube-logo.png";
import { SocialName } from "@/commons/url-mapping.common";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import {
  ISocialTaskResponse,
  useSocialTask,
} from "@/services/apis/social-task";
import { IVerificationResponse } from "@/services/apis/user/types";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import translations
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TaskDetailModal from "./components/TaskDetailModal";

// Unified social verification function using switch case
const useSocialVerification = (social: string) => {
  let verificationData: IVerificationResponse | null = null;
  switch (social) {
    case SocialName.YouTube: {
      const googleService = require("@/services/apis/user/google-service");
      const { data: googleVerificationData } =
        googleService.useCheckGoogleVerification();
      verificationData = googleVerificationData;
      break;
    }
    case SocialName.Facebook: {
      const facebookService = require("@/services/apis/user/facebook-service");
      const { data: facebookVerificationData } =
        facebookService.useCheckFacebookVerification();
      verificationData = facebookVerificationData;
      break;
    }
    case SocialName.Twitter: {
      const twitterService = require("@/services/apis/user/twitter-service");
      const { data: twitterVerificationData } =
        twitterService.useCheckTwitterVerification();
      verificationData = twitterVerificationData;
      break;
    }
    case SocialName.Telegram: {
      const telegramService = require("@/services/apis/user/telegram-service");
      const { data: telegramVerificationData } =
        telegramService.useCheckTelegramVerification();
      verificationData = telegramVerificationData;
      break;
    }
    case SocialName.TikTok: {
      const tiktokService = require("@/services/apis/user/tiktok-service");
      const { data: tiktokVerificationData } =
        tiktokService.useCheckTikTokVerification();
      verificationData = tiktokVerificationData;
      break;
    }
    case SocialName.Instagram: {
      const instagramService = require("@/services/apis/user/instagram-service");
      const { data: instagramVerificationData } =
        instagramService.useCheckInstagramVerification();
      instagramService.useInstagramAuthLink();
      verificationData = instagramVerificationData;
      break;
    }
    default:
      break;
  }

  return { verificationData };
};

const SocialTaskScreen: React.FC = () => {
  const { translations } = useConfigStore(); // Access translations
  const { getUserName } = useAuthStore();
  const { social } = useParams<{ social: string }>();

  const { verificationData } = useSocialVerification(social || "");
  const [selectedTask, setSelectedTask] = useState<ISocialTaskResponse | null>(
    null
  );
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  const { data: socialTaskData } = useSocialTask({
    variables: { social: social || "" },
  });

  const handleVerificationClick = () => {
    if (verificationData?.auth_link) {
      window.location.href = verificationData.auth_link;
    }
  };

  const getSocialImage = () => {
    switch (social) {
      case SocialName.Facebook:
        return FacebookLogoPng;
      case SocialName.YouTube:
        return YoutubeLogoPng;
      case SocialName.Telegram:
        return TelegramLogoPng;
      case SocialName.Twitter:
        return TwitterLogoPng;
      case SocialName.TikTok:
        return TikTokLogoPng;
      case SocialName.Instagram:
        return InstagramLogoPng;
      default:
        return null;
    }
  };

  const infoSocial = {
    image: getSocialImage(),
    description: translations.social_task_screen.finish_tasks_to_earn,
  };

  const renderVerificationMessage = () => {
    if (
      verificationData?.status === "unverified" &&
      verificationData?.auth_type === "oauth"
    ) {
      return (
        <VerificationMessageOauth
          platform={social || ""}
          onClick={handleVerificationClick}
        />
      );
    }
    if (
      verificationData?.status === "unverified" &&
      verificationData?.auth_type === "post_hashtag_facebook"
    ) {
      return (
        <VerificationMessagePostHashtagFacebook
          platform={social || ""}
          hashtagText={`#${verificationData.verify_code}` || ""}
        />
      );
    }
    if (
      verificationData?.status === "unverified" &&
      verificationData?.auth_type === "post_hashtag_twitter"
    ) {
      return (
        <VerificationMessagePostHashtagWitter
          platform={social || ""}
          hashtagText={`#${verificationData.verify_code}` || ""}
        />
      );
    }
    if (verificationData === null || verificationData === undefined) {
      return (
        <div className="flex justify-center items-center h-20">
          <svg
            className="animate-spin h-6 w-6 text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-90"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
            ></path>
          </svg>
          <span className="ml-2 text-white">Loading...</span>
        </div>
      );
    }
  };

  return (
    <PhoneScreenLayout>
      <TopUserProfile username={getUserName()} />
      <div className="flex flex-col items-center mb-6">
        <img
          src={infoSocial.image}
          alt="Social logo"
          className="w-24 h-24 mb-4"
        />
        <h2 className="text-white text-xl font-semibold">
          {infoSocial.description}
        </h2>
      </div>

      {renderVerificationMessage()}

      <div className="flex-col">
        {verificationData?.status === "verified" &&
          socialTaskData &&
          socialTaskData.map((task) => (
            <div
              key={task.id}
              className="bg-gradient-card rounded-[16px] flex items-center justify-between p-2 mb-4 shadow-lg transition-transform transform hover:scale-[1.02]"
              onClick={() => {
                setSelectedTask(task);
                setTaskModalOpen(true);
              }}
            >
              <div className="flex items-center">
                <img
                  src={getSocialImage()}
                  alt="Task Logo"
                  className="w-12 h-12 mr-4"
                />
                <h5 className="text-white text-lg font-medium">{task.name}</h5>
              </div>
              <div className="flex items-center">
                <img
                  src={GameCoinLogoPng}
                  alt="Coin Icon"
                  className="w-8 h-8"
                />
                <h5 className="text-white text-lg font-medium mr-2">
                  +{Number(task.reward_amount)}
                </h5>

                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 18L18 12L12 6" stroke="white" strokeWidth="2" />
                  <path d="M6 18L12 12L6 6" stroke="white" strokeWidth="2" />
                </svg>
              </div>
            </div>
          ))}
      </div>

      <NavBar />

      <TaskDetailModal
        task={selectedTask}
        open={taskModalOpen}
        onClose={() => setTaskModalOpen(false)}
      />
    </PhoneScreenLayout>
  );
};

export default SocialTaskScreen;

const VerificationMessageOauth: React.FC<{
  platform: string;
  onClick: () => void;
}> = ({ platform, onClick }) => {
  const { translations } = useConfigStore(); // Access translations

  return (
    <div className="flex flex-col items-center mb-6">
      <p className="text-white text-lg mb-2 text-center">
        {translations.social_task_screen.verify_account.replace(
          "{platform}",
          platform
        )}
      </p>
      <button
        onClick={onClick}
        className="bg-gradient-to-r from-blue-400 to-purple-400 text-white font-bold py-2 px-4 rounded"
      >
        {translations.social_task_screen.verify_now}
      </button>
    </div>
  );
};

export const VerificationMessagePostHashtagFacebook: React.FC<{
  platform: string;
  hashtagText: string;
}> = ({ platform, hashtagText }) => {
  const { translations } = useConfigStore();
  const [copied, setCopied] = useState(false);

  const message = `I am interested to know King of Shiba. This hashtag helps to verify my ${platform} account: ${hashtagText}`;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  return (
    <div className="flex flex-col items-center mb-6 w-full max-w-lg">
      {/* Instructional Text */}
      <p className="text-white text-lg mb-4 text-center">
        {translations.social_task_screen.verify_post_hashtag_message.replace(
          "{platform}",
          platform
        )}
      </p>

      {/* Textarea with the message */}
      <textarea
        readOnly
        value={message}
        className="bg-gray-200 text-gray-700 px-4 py-2 rounded w-full text-center focus:outline-none resize-none"
        rows={3}
      ></textarea>

      {/* Copy Button */}
      <button
        onClick={handleCopy}
        className="mt-3 bg-gradient-to-r from-blue-400 to-purple-400 text-white font-bold py-2 px-6 rounded focus:outline-none hover:opacity-90 transition"
      >
        {copied
          ? translations.social_task_screen.copied
          : translations.social_task_screen.copy_text}
      </button>
    </div>
  );
};

export const VerificationMessagePostHashtagWitter: React.FC<{
  platform: string;
  hashtagText: string;
}> = ({ platform, hashtagText }) => {
  const { translations } = useConfigStore();
  const [copied, setCopied] = useState(false);

  const message = `I am interested to know King of Shiba. This hashtag helps to verify my ${platform} account: ${hashtagText}`;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  return (
    <div className="flex flex-col items-center mb-6 w-full max-w-lg">
      {/* Instructional Text */}
      <p className="text-white text-lg mb-4 text-center">
        <a
          href="https://x.com/duysyvincent/status/1889305004449882297"
          target="_blank"
          rel="noreferrer"
        >
          Comment in this post with the hashtag {hashtagText} to verify your{" "}
          {platform} account.
        </a>{" "}
      </p>

      {/* Textarea with the message */}
      <textarea
        readOnly
        value={message}
        className="bg-gray-200 text-gray-700 px-4 py-2 rounded w-full text-center focus:outline-none resize-none"
        rows={3}
      ></textarea>

      {/* Copy Button */}
      <button
        onClick={handleCopy}
        className="mt-3 bg-gradient-to-r from-blue-400 to-purple-400 text-white font-bold py-2 px-6 rounded focus:outline-none hover:opacity-90 transition"
      >
        {copied
          ? translations.social_task_screen.copied
          : translations.social_task_screen.copy_text}
      </button>
    </div>
  );
};
