import en from "@/locales/en.json"; // default fallback language
import vi from "@/locales/vi.json";
import create from "zustand";

// Define the interface for your configuration state
interface ConfigState {
  language: string;
  translations: Record<string, any>;
  vibrate: boolean;
  sound: boolean;
  setLanguage: (language: string) => void;
  toggleVibrate: () => void;
  toggleSound: () => void;
  loadConfig: () => void;
  saveConfig: () => void;
}

// Utility function to load config from localStorage
const loadConfigFromLocalStorage = (): Omit<
  ConfigState,
  | "setLanguage"
  | "toggleVibrate"
  | "toggleSound"
  | "loadConfig"
  | "saveConfig"
  | "translations"
> => {
  const config = localStorage.getItem("gameSettings");
  if (config) {
    return JSON.parse(config);
  }
  return {
    language: "en", // default language
    vibrate: false,
    sound: true,
  };
};

// Function to load translations based on selected language
const loadTranslations = (language: string): Record<string, any> => {
  switch (language) {
    case "vi":
      return vi;

    case "en":
    default:
      return en;
  }
};

export const useConfigStore = create<ConfigState>((set, get) => ({
  ...loadConfigFromLocalStorage(), // Load initial state from localStorage or use defaults
  translations: loadTranslations(loadConfigFromLocalStorage().language), // Load translations based on saved language

  // Set the language and update translations
  setLanguage: (language: string) => {
    set({ language, translations: loadTranslations(language) });
    get().saveConfig(); // Persist to localStorage whenever it changes
  },

  // Toggle vibration setting
  toggleVibrate: () => {
    set((state) => ({ vibrate: !state.vibrate }));
    get().saveConfig(); // Persist to localStorage whenever it changes
  },

  // Toggle sound setting
  toggleSound: () => {
    set((state) => ({ sound: !state.sound }));
    get().saveConfig(); // Persist to localStorage whenever it changes
  },

  // Load configuration from localStorage
  loadConfig: () => {
    const config = loadConfigFromLocalStorage();
    set({ ...config, translations: loadTranslations(config.language) });
  },

  // Save the configuration to localStorage
  saveConfig: () => {
    const { language, vibrate, sound } = get();
    const config = { language, vibrate, sound };
    localStorage.setItem("gameSettings", JSON.stringify(config));
  },
}));
