import { createMutation, createQuery } from "react-query-kit";
import { checkTelegramVerification, verifyTelegramToken } from "./request";
import { ITelegramVerificationResponse, ITelegramVerifyRequest } from "./types";

export const useVerifyTelegramToken = createMutation<
  ITelegramVerificationResponse,
  ITelegramVerifyRequest
>({
  mutationFn: verifyTelegramToken,
});

export const useCheckTelegramVerification = createQuery({
  queryKey: ["useCheckTelegramVerification"],
  fetcher: (): Promise<ITelegramVerificationResponse> =>
    checkTelegramVerification(),
});
