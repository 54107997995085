import { createMutation, createQuery } from "react-query-kit";
import { checkInstagramVerification, verifyInstagramToken } from "./request";
import {
  IInstagramVerificationResponse,
  IInstagramVerifyRequest,
} from "./types";

export const useVerifyInstagramToken = createMutation<
  IInstagramVerificationResponse,
  IInstagramVerifyRequest
>({
  mutationFn: verifyInstagramToken,
});

export const useCheckInstagramVerification = createQuery({
  queryKey: ["useCheckInstagramVerification"],
  fetcher: (): Promise<IInstagramVerificationResponse> =>
    checkInstagramVerification(),
});
