import api from "@/services/apis/api";

import { IGoogleVerificationResponse, IGoogleVerifyRequest } from "./types";

export const verifyGoogleToken = async (
  data: IGoogleVerifyRequest
): Promise<IGoogleVerificationResponse> => {
  const response: IGoogleVerificationResponse = await api.post(
    "/api/user/social/google/verify/",
    data
  );
  return response;
};

export const checkGoogleVerification =
  async (): Promise<IGoogleVerificationResponse> => {
    const response: IGoogleVerificationResponse = await api.get(
      "/api/user/social/google/is-verified/"
    );
    return response;
  };
