import api from "@/services/apis/api";
import {
  IInstagramVerificationResponse,
  IInstagramVerifyRequest,
} from "./types";

export const verifyInstagramToken = async (
  data: IInstagramVerifyRequest
): Promise<IInstagramVerificationResponse> => {
  const response: IInstagramVerificationResponse = await api.post(
    "/api/user/social/instagram/verify/",
    data
  );
  return response;
};

export const checkInstagramVerification =
  async (): Promise<IInstagramVerificationResponse> => {
    const response: IInstagramVerificationResponse = await api.get(
      "/api/user/social/instagram/is-verified/"
    );
    return response;
  };
