import api from "@/services/apis/api";
import { ITwitterVerificationResponse, ITwitterVerifyRequest } from "./types";

export const verifyTwitterToken = async (
  data: ITwitterVerifyRequest
): Promise<ITwitterVerificationResponse> => {
  const response: ITwitterVerificationResponse = await api.post(
    "/api/user/social/twitter/verify/",
    data
  );
  return response;
};

export const checkTwitterVerification =
  async (): Promise<ITwitterVerificationResponse> => {
    const response: ITwitterVerificationResponse = await api.get(
      "/api/user/social/twitter/is-verified/"
    );
    return response;
  };
