import DailyTaskPng from "@/assets/images/earn-screen/daily-task.png";
import FlagShibaPng from "@/assets/images/earn-screen/flag-shiba.png";
import LuckyBoxPng from "@/assets/images/earn-screen/lucky-box.png";
import PlanetGroupPng from "@/assets/images/earn-screen/planet-group.png";
import ShibaPlanetPng from "@/assets/images/earn-screen/shiba-planet.png";
import FacebookLogoPng from "@/assets/images/earn-screen/social-screen/facebook-logo.png";
import InstagramLogoPng from "@/assets/images/earn-screen/social-screen/instagram-logo.png";
import TelegramLogoPng from "@/assets/images/earn-screen/social-screen/telegram-logo.png";
import TikTokLogoPng from "@/assets/images/earn-screen/social-screen/tiktok-logo.png";
import TwitterLogoPng from "@/assets/images/earn-screen/social-screen/twitter-logo.png";
import YoutubeLogoPng from "@/assets/images/earn-screen/social-screen/youtube-logo.png";
import React from "react";
import { useNavigate } from "react-router-dom";

import { SocialName, UrlMapping } from "@/commons/url-mapping.common";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import the store for translations

type Props = {
  image: string;
  nameTask: string;
  description: string;
  titleButton: string;
  onClick: () => void;
};

const CardTask: React.FC<Props> = ({
  image,
  nameTask,
  description,
  titleButton,
  onClick,
}) => {
  return (
    <div
      className="bg-gradient-card rounded-[16px] shadow-lg text-white cursor-pointer"
      onClick={onClick}
    >
      <div className="flex justify-center items-center border-b-[1px] border-[#4A5696]">
        <div className="size-16 border-r-[1px] border-[#4A5696] flex justify-center items-center">
          <img src={image} alt="Task Icon" className="w-full h-auto mx-auto" />
        </div>
        <div className="flex-col justify-center items-center w-full px-1">
          <div>
            <b>
              {nameTask} {">"}
            </b>
          </div>
          <div className="text-[0.8rem]">{description}</div>
        </div>
      </div>
      <div className="h-10 flex justify-center items-center">
        <div className="text-[#F0F900]">{titleButton}</div>
      </div>
    </div>
  );
};

const EarnScreen: React.FC = () => {
  const navigate = useNavigate();
  const { getUserName } = useAuthStore();
  const { translations } = useConfigStore(); // Access translations

  // Define tasks array
  const dailyTasks = [
    {
      image: DailyTaskPng,
      nameTask: translations.earn_screen.daily_reward,
      description: translations.earn_screen.attendance_check,
      titleButton: translations.earn_screen.check_continuously,
      path: "daily-task",
    },
    {
      image: LuckyBoxPng,
      nameTask: translations.earn_screen.daily_game,
      description: translations.earn_screen.lucky_box,
      titleButton: translations.earn_screen.mysterious_coins,
      path: "daily-game",
    },
  ];

  const socialTasks = [
    {
      image: FacebookLogoPng,
      nameTask: "Facebook",
      description: translations.earn_screen.finish_task,
      titleButton: translations.earn_screen.check_continuously,
      path: `${UrlMapping.earn_social}/${SocialName.Facebook}`,
    },
    {
      image: YoutubeLogoPng,
      nameTask: "Youtube",
      description: translations.earn_screen.finish_task,
      titleButton: translations.earn_screen.mysterious_coins,
      path: `${UrlMapping.earn_social}/${SocialName.YouTube}`,
    },
    {
      image: TelegramLogoPng,
      nameTask: "Telegram",
      description: translations.earn_screen.finish_task,
      titleButton: translations.earn_screen.check_continuously,
      path: `${UrlMapping.earn_social}/${SocialName.Telegram}`,
    },
    {
      image: TwitterLogoPng,
      nameTask: "Twitter",
      description: translations.earn_screen.finish_task,
      titleButton: translations.earn_screen.check_continuously,
      path: `${UrlMapping.earn_social}/${SocialName.Twitter}`,
    },
    {
      image: TikTokLogoPng,
      nameTask: "TikTok",
      description: translations.earn_screen.finish_task,
      titleButton: translations.earn_screen.check_continuously,
      path: `${UrlMapping.earn_social}/${SocialName.TikTok}`,
    },
    {
      image: InstagramLogoPng,
      nameTask: "Instagram",
      description: translations.earn_screen.finish_task,
      titleButton: translations.earn_screen.check_continuously,
      path: `${UrlMapping.earn_social}/${SocialName.Instagram}`,
    },
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <PhoneScreenLayout>
      <TopUserProfile username={getUserName()} />

      <div>
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold">
            {translations.earn_screen.coin_universe}
          </h1>
        </div>
        <div className="w-full flex justify-center items-center">
          <img src={FlagShibaPng} alt="Shiba King" className="w-1/2" />
        </div>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            {translations.earn_screen.daily_tasks}
          </h2>
          <div className="grid grid-cols-2 gap-3">
            {dailyTasks.map((task, index) => (
              <CardTask
                key={index}
                image={task.image}
                nameTask={task.nameTask}
                description={task.description}
                titleButton={task.titleButton}
                onClick={() => handleNavigation(task.path)}
              />
            ))}
          </div>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            {translations.earn_screen.social_network_tasks}
          </h2>
          <div className="grid grid-cols-2 gap-3">
            {socialTasks.map((task, index) => (
              <CardTask
                key={index}
                image={task.image}
                nameTask={task.nameTask}
                description={task.description}
                titleButton={task.titleButton}
                onClick={() => handleNavigation(task.path)}
              />
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-14">
            {translations.earn_screen.explore_planet}
          </h2>
          <div
            className="bg-gradient-card py-2 rounded-[16px] flex flex-col items-center cursor-pointer"
            onClick={() => handleNavigation("energy-collection")}
          >
            <div className="flex border-b-[1px] border-[#4A5696]">
              <div className="w-16 relative">
                <img
                  src={ShibaPlanetPng}
                  alt="Pay to get more energy"
                  className="scale-[1.5] mb-2 absolute bottom-3 right-[10px]"
                />
              </div>
              <b>{translations.earn_screen.comment_id_codes_message}</b>
            </div>
            <div className="w-full">
              <img
                src={PlanetGroupPng}
                alt="Planet 1"
                className="w-full h-auto"
              />
            </div>
          </div>
        </section>
      </div>

      <NavBar />
    </PhoneScreenLayout>
  );
};

export default EarnScreen;
